import { MouseEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FCWithChildren } from '../../types/FCWithChildren';
import { Heading, HeadingSize } from '../shared/text/Heading';
import Tooltip from '../shared/Tooltip';
import DashboardBlock from './DashboardBlock';
import { Namespace, ParseKeys } from 'i18next';
import { FlatNamespace } from 'i18next';

export enum ChangeMode {
  NEUTRAL,
  POSITIVE_GOOD,
  NEGATIVE_GOOD,
  NOT_APPLICABLE,
}

const Footer: FCWithChildren = (props) => {
  if (!props.children) {
    return null;
  }
  return (
    <div className="flex items-center py-2">
      <div className="w-full">{props.children}</div>
    </div>
  );
};

type StatsBlockType<T> = FCWithChildren<T> & {
  Footer: typeof Footer;
};

type StatsBlockProps = {
  headingKey: ParseKeys<Namespace>;
  statsKey?: ParseKeys<Namespace>;
  stat: string | number;
  translationNamespace: FlatNamespace;
  viewKey?: ParseKeys<Namespace>;
  change: number;
  changeMode?: ChangeMode;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
};

export const StatsBlock: StatsBlockType<StatsBlockProps> = (props) => {
  const { headingKey, statsKey, stat, translationNamespace, viewKey, change, onClick, children, changeMode = ChangeMode.POSITIVE_GOOD } = props;
  const { t } = useTranslation([translationNamespace, 'client-dashboard', 'common']);

  const changeBad = useMemo(() => {
    return changeMode === ChangeMode.NEUTRAL ? false : changeMode === ChangeMode.POSITIVE_GOOD ? change < 0 : change > 0;
  }, [change, changeMode]);

  return (
    <DashboardBlock>
      <DashboardBlock.Body>
        <div className="border-primary-2 text-dpm-14 -mx-4 flex justify-between border-b px-4">
          <Tooltip text={t(headingKey) as string}>
            {(tooltip) => (
              <div data-cy="heading" className="truncate font-medium" {...tooltip}>
                {t(headingKey) as string}
              </div>
            )}
          </Tooltip>

          {onClick && viewKey && (
            <div
              className="cursor-pointer font-medium"
              tabIndex={-1}
              role="button"
              onClick={onClick}
              data-cy="view-more"
              aria-label={t(viewKey) as string}
            >
              {t(viewKey) as string}
            </div>
          )}
        </div>
        <div className="mt-2 flex items-center gap-2">
          <Heading size={HeadingSize.H3} className="font-normal" data-cy="stat-count">
            {stat}
          </Heading>

          {statsKey && (
            <Heading size={HeadingSize.H4} actualSize={HeadingSize.H5} className="font-normal" data-cy="stat-title">
              {t(statsKey) as string}
            </Heading>
          )}
        </div>
        {changeMode !== ChangeMode.NOT_APPLICABLE && (
          <div className={`text-dpm-12 flex items-center gap-2 font-medium ${changeBad ? 'text-dashboard-3' : 'text-dashboard-4'}`}>
            {t('client-dashboard:stats.stats-block-change', {
              amount: (change >= 0 ? '+' : '') + change + (statsKey ? t(statsKey) : ''),
            })}
          </div>
        )}
      </DashboardBlock.Body>
      <DashboardBlock.Footer>{children}</DashboardBlock.Footer>
    </DashboardBlock>
  );
};

StatsBlock.Footer = Footer;
