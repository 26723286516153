import { FCWithChildren } from '../../types/FCWithChildren';
import InfoIcon from '../shared/icon/InfoIcon';

const NoDataPlaceholder: FCWithChildren = (props) => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <InfoIcon className="bg-primary-1 text-primary-1 h-14 w-14 rounded-full bg-opacity-10 p-4" />
      <div className="text-dpm-18 text-color-3 mt-8">{props.children}</div>
    </div>
  );
};

export default NoDataPlaceholder;
